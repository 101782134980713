import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProvinces(ctx, params) {
      return useJwt.getProvince(params).then(response => response);
    },
    createProvince(ctx, data) {
      return useJwt.createFloor(data).then(response => response);
    },
    updateProvince(ctx, data) {
      return useJwt.updateFloor(data).then(response => response);
    },
    deleteProvinces(ctx, data) {
      return useJwt.deleteFloors(data).then(response => response);
    },
  },
};
