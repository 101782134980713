import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import provinceStoreModule from '../provinceStoreModule';

export default function useProvinceList() {
  const STORE_MODULE_NAME = 'province';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, provinceStoreModule);
  }

  const toastification = toast();

  // add / edit
  const blankItem = {
    id: '',
    name: '',
    prefix: '',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Thao tác',
      field: 'action',
      sortable: false,
      width: '100px',
      hidden: true,
    },
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'Phân loại',
      field: 'prefix',
    },
    {
      label: 'Tên',
      field: 'name',
    },

  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 100,
  });

  //   API Call

  const fetchProvinces = () => {
    store
      .dispatch('province/fetchProvinces', serverParams.value)
      .then(response => {
        totalRecords.value = response.totalRecords;
        rows.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };
  const refetchProvinces = () => {
    fetchProvinces();
  };

  const deleteProvinces = provinces => {
    store
      .dispatch('province/deleteProvinces', {
        ids: provinces.map(_obj => _obj.id),
      })
      .then(() => {
        fetchProvinces();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createProvince = () => {
    store
      .dispatch('province/createProvince', {
        name: item.value.name,
      })
      .then(() => {
        fetchProvinces();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchProvinces();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchProvinces,
    fetchProvinces,
    deleteProvinces,
    createProvince,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
  };
}
